import React, { useContext, useEffect } from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import Productsitems from './subcomponents/Product/products'
import { useState } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../services/api/axios';
import { GlobalContext } from '../../../store/GlobalContext';
import SearchNavbar from '../../../components/SearchNavbar/SearchNavbar';
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection';
import { useTranslation } from 'react-i18next';
const cookies = new Cookies()
const MenProductPage = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const { t } = useTranslation('product')
    const [checkCart, setCheckCart] = useState(false);
    const [query, setQuery] = useState("");
    const [searchType, setSearchType] = useState(true)
    const Navigate = useNavigate();
    return (
        <>
            <Header />
            <SearchAndGenderSection />
            <section id="breadcrumb">
                <nav className='row px-5 mx-3' aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item text-dark opacity-5"><Link to="/">SHOP</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">I’m interested in Mens Clothes</li>
                    </ol>
                </nav>
            </section>
            <section id="hotels" className="section-with-bg wow fadeInUp">
                <div className="row px-5 mx-3">
                    <Productsitems
                        genderbtn="I'm interested in Female's clothes"
                        btn="about-btn-women scrollto"
                        location="/product_page_women"
                        gender={"MALE"}
                        checkCart={checkCart}
                        onChange={value => setCheckCart(value)}
                        query={query}
                        searchType={searchType}
                        setQuery={setQuery}
                        setSearchType={setSearchType}
                    />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default MenProductPage