import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../../store/GlobalContext';

const FeaturedSellers = () => {
    const { t } = useTranslation('landingpage');
    const { state } = useContext(GlobalContext)
    const featuredSellers = state.featuredSellers ?? []
    const Navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const maxLength = 80;

    const toggleDescription = () => {
        setExpanded(!expanded);
    };
    return (
        <section id="feature-seller" className="feature-seller py-5">
            <div className="container-fluid mt-3" data-aos="fade-up">
                <div className="section-title text-center">
                    <h2 className='mb-0'>{t('heading5')}</h2>
                </div>

                <div className="row px-5 mx-3">
                    {featuredSellers?.map((elem) => (
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => Navigate(`/seller/${elem?.userName}`)}
                            className="col-lg-4 col-md-6 d-flex align-items-stretch" key={elem?.id}>
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                {/* <div className="member-img">
                                    <img src={elem?.image} className="img-fluid image-item" alt="" />
                                </div>
                                <div class="row">
                                    <div class="col-9">
                                        <div className="member-img">
                                            <img src={elem?.image} className="img-fluid image-item product-image" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="product-image-thumbs">
                                            <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                            <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                            <div className="product-image-thumb active"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                            <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                        </div>
                                    </div>
                                </div> */}



                                {/* <div className="member-img">
                                    <img src={elem?.image} className="img-fluid image-item main-image" alt="" />
                                    <div className="product-image-thumbs">
                                        <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                        <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                        <div className="product-image-thumb active"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                        <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                    </div>
                                </div> */}

                                <div class="row">
                                    <div class="col-9">
                                        <div className="member-img">
                                            <img src={elem?.image} className="img-fluid image-item main-image" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                    <div className='image-container'>
                                        <div class="product-image-thumbs">
                                            <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                            <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                            <div className="product-image-thumb active"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                            <div className="product-image-thumb"><img src={elem?.image} className='img-fluid' alt="" /></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="member-info">
                                    {/* <h5 className='mb-0'>Black Bra worn at 3 days</h5> */}
                                    <div className='d-flex align-items-center'>
                                        <h4>{elem?.userName}</h4>
                                        <i className="fa-regular fa-circle-check fa-check"></i>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className="stars my-2 me-3">
                                            {elem?.rating > 0 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {elem?.rating > 1 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {elem?.rating > 2 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {elem?.rating > 3 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {elem?.rating > 4 && <i className="fa-solid fa-star fa-lg"></i>}
                                        </div>
                                        <p className='my-2'>{elem?.reviews} reviews</p>
                                    </div>
                                    <p className='mb-0'>Body Type: {elem?.bodyType}</p>
                                    <p>Age: {elem?.age}</p>
                                    <div className="description">
                                        <p>{expanded ? elem?.description : elem?.description?.length > maxLength ? `${elem?.description.slice(0, maxLength)}... ` : elem?.description}
                                            {elem?.description?.length > maxLength && (
                                                <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                                    {expanded ? 'Read less' : 'Read more'}
                                                </button>
                                            )}
                                        </p>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <button>{t('text5')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                    <div className='text-center my-4'>
                        <button type='submit' className='see-more'>{t('text6')}</button>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default FeaturedSellers;