import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button'
import { useTranslation } from "react-i18next";
const Timer = (props) => {
    const { t } = useTranslation('registration')
    const [seconds, setSeconds] = useState(59);
    const [btn, setBtn] = useState(false)
    useEffect(() => {
        if (props.reset) {
            setSeconds(59)
            props.setReset(false)
            setBtn(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reset])
    useEffect(() => {
        if (seconds === 0) {
            setBtn(true)
            props.setValidOtp('')
        } else {
            var timer;
            timer = setInterval(() => {
                setSeconds(seconds - 1)

            }, 1000)
        }
        return () => clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="timer">
            <div className="container">
                <div className="timer_container">

                    <p >
                        <center>  <b style={{ cursor: "pointer", color: "white" }}>
                            &nbsp;{t('text17')} 00:{seconds < 10 ? "0" + seconds : seconds}
                            {/* &nbsp;<Button variant="light" onClick={props.getOtp} disabled={!btn}><u>Resend Otp</u></Button> */}
                        </b></center>
                    </p>
                    <center><Button variant="light" onClick={props.getOtp} disabled={!btn}>{t('resendbtn')}</Button></center>
                </div>
            </div>
        </div>
    )
}
export default Timer;