import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import 'react-phone-number-input/style.css'
import ScrollButton from "./components/Buttons/ScrollButton"
import { GlobalStateProvider } from './store/GlobalState'
import './i18n';

// import "./static/css/style.css"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  // <React.StrictMode>
  <>
    <GlobalStateProvider>
        <App />
    </GlobalStateProvider>
    <ScrollButton />
  </>
  // </React.StrictMode>
);