import React, { useContext, useEffect } from 'react';
import { fetchBlogs, fetchFeaturedProducts, fetchFeaturedSellers } from './services/public/public'; // replace with your actual API functions
import { GlobalContext } from './store/GlobalContext';
import { fetchCartItems, fetchCategoryList, fetchCountryList, fetchFavorites, fetchOrderItems, fetchUserInfo, fetchProducts } from './services/buyer/buyer';

const AppWrapper = ({ children }) => {
    const { state, dispatch } = useContext(GlobalContext)
    const language = state.language;
    const isLoggedIn = state.isLoggedIn;
    useEffect(() => {
        const fetchPublicData = async () => {
            const featuredSellers = await fetchFeaturedSellers(language);
            const featuredProducts = await fetchFeaturedProducts(language);
            const blogs = await fetchBlogs(language);

            dispatch({ type: 'SET_FEATURED_PRODUCTS', payload: featuredProducts });
            dispatch({ type: 'SET_FEATURED_SELLERS', payload: featuredSellers });
            dispatch({ type: 'BLOGS', payload: blogs });
        };

        fetchPublicData();
    }, [dispatch, language]);
    useEffect(() => {
        const fetchProtectedData = async () => {
            const countryList = await fetchCountryList(language);
            const categoryList = await fetchCategoryList(language);

            dispatch({ type: 'COUNTRY_LIST', payload: countryList });
            dispatch({ type: 'CATEGORY_LIST', payload: categoryList });
        };
        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchProtectedData();
        }
    }, [dispatch, language, isLoggedIn]);

    useEffect(() => {
        const fetchProductsData = async () => {
            const products = await fetchProducts(language, state.productsApiParams);
            dispatch({ type: 'SET_PRODUCTS', payload: products });
        }
        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchProductsData();
        }
    }, [dispatch, language, isLoggedIn, state.refreshProducts, state.productsApiParams])

    useEffect(() => {
        const fetchOrdersData = async () => {
            const orderItems = await fetchOrderItems(language, state.ordersApiParams.trackingId, state.ordersApiParams.startDate, state.ordersApiParams.endDate);
            dispatch({ type: 'ORDER_ITEMS', payload: orderItems });
        }
        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchOrdersData();
        }
    }, [dispatch, language, isLoggedIn, state.refreshOrders, state.ordersApiParams])

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const userInfo = await fetchUserInfo(language);
                if (userInfo) {
                    dispatch({ type: 'USER_INFO', payload: userInfo });
                } else {
                    console.error('Failed to fetch user info.');
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchProfileData();
        }
    }, [dispatch, language, isLoggedIn, state.refreshProfile])

    useEffect(() => {
        const userInfo = state.user
        const checkoutInfo = {
            ...state.checkoutInfo,
            buyer_email: userInfo.email,
            buyer_fullName: userInfo.userName,
            buyer_phone_no: userInfo.phoneNo,
            shippingAddress: userInfo.address,
        };
        dispatch({ type: 'CHECKOUT_INFO', payload: checkoutInfo });
    }, [state.user])

    useEffect(() => {
        const fetchFavoritesData = async () => {
            const favorites = await fetchFavorites(language);
            dispatch({ type: 'SET_FAVORITE_ITEMS', payload: favorites });
        }
        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchFavoritesData();
        }
    }, [dispatch, language, isLoggedIn, state.refreshFavorites])

    useEffect(() => {
        const fetchCartData = async () => {
            try {
                const cartItems = await fetchCartItems(language);

                const quantity = cartItems?.reduce((total, cartItem) => {
                    return total + (cartItem?.items?.reduce((itemTotal, item) => itemTotal + item?.quantity, 0) || 0);
                }, 0);

                const price = cartItems?.reduce((total, cartItem) => {
                    return total + (cartItem?.items?.reduce((itemTotal, item) => itemTotal + (item?.quantity * parseFloat(item?.price)), 0) || 0);
                }, 0);

                const checkoutInfo = { ...state.checkoutInfo, price, quantity };

                dispatch({ type: 'CHECKOUT_INFO', payload: checkoutInfo });
                dispatch({ type: 'SET_CART_ITEMS', payload: cartItems });
            } catch (error) {
                console.error('Error fetching cart data:', error);
            }
        };

        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchCartData();
        }
    }, [dispatch, language, isLoggedIn, state.refreshCart])

    useEffect(() => {
        const deliveryOptions = state.checkoutInfo.delivery_method
        const paymentMethod = state.checkoutInfo.payment_method
        const price = state.checkoutInfo.price

        let Total = price;
        let shippingFee = 0;
        let transactionFee = 0;

        if (deliveryOptions === 'PICK') {
            shippingFee = 0;
        } else if (deliveryOptions === 'NINJA') {
            shippingFee = 6;
        }

        if (paymentMethod === 'STRIPE') {
            transactionFee = (2.5 * (price / 100)).toFixed(2);
        } else if (paymentMethod === 'PAYNOW') {
            transactionFee = 0;
        }

        Total = price + shippingFee + parseFloat(transactionFee);
        let checkoutInfo = { ...state.checkoutInfo }
        checkoutInfo.orderTotal = Total;
        checkoutInfo.shipping_price = shippingFee;
        checkoutInfo.transactionFee = transactionFee;
        dispatch({ type: "CHECKOUT_INFO", payload: checkoutInfo })
    }, [state.checkoutInfo?.delivery_method, state.checkoutInfo?.payment_method, state.checkoutInfo?.price])

    return <>{children}</>;
};

export default AppWrapper;
