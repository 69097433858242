import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from "universal-cookie";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import LoadingBar from 'react-top-loading-bar'
import MyAlert from '../../../../components/Alerts/Alert/alert'
import { GlobalContext } from '../../../../store/GlobalContext';
import { AddToCart } from '../../../../utils/cart/cartUtils';
import { AddToFavorite } from '../../../../utils/favorite/favorite';
import SearchNavbar from '../../../../components/SearchNavbar/SearchNavbar';
import axios from '../../../../services/api/axios';
import SearchAndGenderSection from '../../../../components/SearchAndGenderSection/SearchAndGenderSection';
const cookies = new Cookies();

const ProductDetailsMain = () => {
  const { id } = useParams();
  const { state } = useContext(GlobalContext);
  const [product, setProduct] = useState({});
  const [showPage, setShowPage] = useState(false)
  const [progress, setProgress] = useState(30)
  const [related, setRelated] = useState([])
  const navigate = useNavigate()
  // const [toggle, setToggle] = useState(false)
  const [text, setText] = useState('');


  useEffect(() => {
    axios.get(`product-detail/related-products/?product=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('access')}`,
        'Accept-Language': state.language
      },
      withCredentials: true
    }).then(res => {
      setShowPage(true);
      setProgress(100);
      setProduct(res?.data?.product)
      setRelated(res?.data?.related_products)

    }).catch(err => {
      console.log(err)
    })
  }, [id])
  console.log('PRODUCT----', product)

  const [expanded, setExpanded] = useState(false);
  const maxLength = 80;

  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      {showPage === true && <>
        {/*<!-- ======= Product Details Sectionn ======= -->*/}
        <section id="speakers-details">
          <div className="container-fluid">
            <MyAlert text={text} setText={setText} />
            <SearchAndGenderSection />
            <Breadcrumb className='d-flex justify-content-start align-items-center bread-container px-5 mx-3 my-5'>
              <Breadcrumb.Item onClick={() => navigate(product?.gender === "MALE" ? "/product_page_men" : "/product_page_women")}>
                SHOP
              </Breadcrumb.Item>
              <Breadcrumb.Item className='Bread-product'
                onClick={() => navigate(product?.gender === "MALE" ? "/product_page_men" : "/product_page_women")}
              >I’m interested in {`${product?.gender === "FEMALE" ? "Womens" : "Mens"}`} Clothes</Breadcrumb.Item>
              <Breadcrumb.Item active className='Bread-product'>{product?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row justify-content-between px-5 mx-3 mb-5">
              <div className="col-lg-6 col-md-10  col-10 mx-auto Product_img" >

                <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    {product?.extra_image1 !== "https://server.fantasysenses.com/media/default.jpg" && <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>}
                    {product?.extra_image2 !== "https://server.fantasysenses.com/media/default.jpg" && <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>}
                    {product?.extra_image3 !== "https://server.fantasysenses.com/media/default.jpg" && <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>}
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={product.image} className="d-block w-100" alt="..." style={{ width: "420px", height: "520px" }} />
                    </div>
                    {product?.extra_image1 !== "https://server.fantasysenses.com/media/default.jpg" && <div className="carousel-item">
                      <img src={product?.extra_image1} className="d-block w-100" alt="..." style={{ width: "420px", height: "520px" }} />
                    </div>}
                    {product?.extra_image2 !== "https://server.fantasysenses.com/media/default.jpg" && <div className="carousel-item">
                      <img src={product?.extra_image2} className="d-block w-100" alt="..." style={{ width: "420px", height: "520px" }} />
                    </div>}
                    {product?.extra_image3 !== "https://server.fantasysenses.com/media/default.jpg" && <div className="carousel-item">
                      <img src={product?.extra_image3} className="d-block w-100" alt="..." style={{ width: "420px", height: "520px" }} />
                    </div>}
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className='col-lg-1 col-md-2 col-sm-2 bg-white'>
                <img src={product.extra_image1} className="d-block w-100" alt="..." style={{ marginBottom: '20px', borderRadius: '8px', border: '2px solid #f47493', height: '130px', objectFit: 'contain' }} />
                <img src={product.extra_image2} className="d-block w-100" alt="..." style={{ marginBottom: '20px', borderRadius: '8px', border: '2px solid #f47493', height: '130px', objectFit: 'contain' }} />
                <img src={product.extra_image3} className="d-block w-100" alt="..." style={{ marginBottom: '20px', borderRadius: '8px', border: '2px solid #f47493', height: '130px', objectFit: 'contain' }} />


              </div>
              <div className="col-lg-5 col-md-10  col-12 mx-auto mt-md-3 mb-3 mt-4">
                <div className="details mt-lg-3 mt-md-3 mt-2">
                  <h2 style={{ marginBottom: "8px" }}>{product.name}</h2>
                  <h4><strong>SGD{product.price}</strong></h4>
                  <strong style={{ color: '#E86669', cursor: "pointer" }} className="mb-5 seller-product-page"><Link to={`/seller/${product?.seller_name}`}>{product?.seller_name}{product?.isVerified && <i className="far fa-check-circle mb-4"></i>}</Link></strong>
                  <p>{product.description}</p>
                  <div className="row">
                    <div className="col-12 d-flex ps-0 ">
                      <AddToCart id={product?.id} />
                      <AddToFavorite id={product?.id} />
                    </div>
                    {product?.quantity < 1 && <i style={{ fontWeight: "lighter" }}>out of stock</i>}

                  </div>
                </div >
              </div >
            </div >
          </div >
        </section >
        {related?.length > 0 &&
          <section id="feature-prod" className="feature-prod py-5">
            <div className="container-fluid mt-3" data-aos="fade-up">
              <div className="section-title text-center">
                <h2 className='text-dark text-uppercase mb-0'>Other Products</h2>
              </div>
              <div className="row px-5 mx-3">
                {related?.map(elem => {
                  return (
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                      <div className="member" data-aos="fade-up" data-aos-delay="100">
                        <Link to={`/product_display_page_men/${elem?.url_link}`} >
                          <div className="member-img" >
                            <img src={elem?.image} className="img-fluid image-item" alt="" />
                          </div>
                        </Link>
                        <div className="member-info" >
                          <Link to={`/product_display_page_men/${elem?.url_link}`}>

                            <h5 className='mb-0'>{elem?.name}</h5>
                            <h4>S${elem?.price}</h4>
                            <div className="description">
                              <p>{expanded ? elem?.description : elem?.description?.length > maxLength ? `${elem?.description?.slice(0, maxLength)}... ` : elem?.description}
                                {elem?.description?.length > maxLength && (
                                  <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                    {expanded ? 'Read less' : 'Read more'}
                                  </button>
                                )}
                              </p>
                            </div>
                          </Link>
                          <div className='d-flex align-items-center mb-2'>
                            <AddToCart id={elem?.id} />
                            <AddToFavorite id={elem?.id} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        }
      </>
      }
    </div>
  )
}

export default ProductDetailsMain;