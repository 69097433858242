import React, { useEffect, useState, useContext } from "react";
import Header from '../../../layout/Header/Header';
import Footer from '../../../layout/Footer/Footer';
import "./SellerProductsList.css"
import { useNavigate } from 'react-router-dom';
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProductEditModel from "../../../components/Modal/ProductEditModel/ProductEditModel"
import Cookies from "universal-cookie";
import LoadingBar from 'react-top-loading-bar'
import ProductModel from "../../../components/Modal/ProductViewModel/ProductViewModel";
import DeleteModal from "../../../components/Modal/DeleteModel/DeleteModal";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../store/GlobalContext";
const cookies = new Cookies();

const SellerProductsList = () => {
    const { state } = useContext(GlobalContext);
    const { t } = useTranslation('sellerproductlist')
    const [showPage, setShowPage] = useState(false)
    const [showModel, setShowModel] = useState(false)
    const [text, setText] = useState();
    const Navigate = useNavigate();
    const [progress, setProgress] = useState(30)
    const axiosPrivate = useAxiosPrivate();
    const [productList, setProductList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [productState, setProductState] = useState(true);
    const [itemName, setItemName] = useState('');
    const [itemType, setItemType] = useState('');
    const [itemId, setItemId] = useState();
    const [searchString, setSearchString] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalItems, setTotalItems] = useState(0);
    const [apiParams, setApiParams] = useState({
        startDate: "",
        endDate: ""
    })
    useEffect(() => {
        axiosPrivate.get(`seller/seller_products_listing/?search_value=${searchString}&start_date=${apiParams.startDate}&end_date=${apiParams.endDate}&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': state.language

                },
                withCredentials: true
            }
        ).then(res => {
            console.log(res.data, 'products')
            setProductList(res.data?.products)
            setTotalItems(res.data?.count)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
    }, [productState, axiosPrivate, apiParams, searchString, currentPage, itemsPerPage])
    const showDeleteModel = (name, id) => {
        setItemName(name)
        setItemType('Product')
        setItemId(id)
        setShowModel(true)
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleDeleteProduct = (id) => {
        axiosPrivate.delete(`seller/delete_seller_product/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': state.language

                },
                withCredentials: true
            }
        ).then(res => {
            setShowModel(false)
            setProductState(prev => !prev)
        }).catch(err => {
            setText(err.code)
        })
    }
    function handleDatesDispatching() {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateRegex.test(startDate) && dateRegex.test(endDate)) {
            const ordersApiParams = { ...apiParams }
            ordersApiParams.startDate = startDate;
            ordersApiParams.endDate = endDate;
            setApiParams(ordersApiParams)
        } else {
            alert("please provide valid dates")
        }
    }
    return (
        <>
            <DeleteModal
                setShowModel={setShowModel}
                showModel={showModel}
                itemType={itemType}
                itemName={itemName}
                itemId={itemId}
                deleteFunction={handleDeleteProduct} />
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage === true &&
                <>
                    <Header />
                    <SearchAndGenderSection />

                    <section id='productsList'>
                        <div className='row px-5 mx-3'>
                            <div className='card mb-5'>
                                <div className='p-4'>
                                    <h2 className='text-uppercase m-0'>{t('dropdownitem1')}</h2>
                                </div>

                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <div className='mx-4'>
                                        <button className='btn-addToCart text-uppercase' onClick={() => Navigate('/add_product')}>{t('btn3')}</button>
                                    </div>
                                    <div>
                                        <span className='mx-2'>{t('text1')}</span>
                                        <input value={startDate} onChange={(e) => setStartDate(e.target.value)} type='date' style={{ borderRadius: '0.4rem' }} />
                                        <i class="fa fa-calendar calender"></i>

                                        <span className='mx-2 mt-2'>{t('text2')}</span>
                                        <input value={endDate} onChange={(e) => setEndDate(e.target.value)} type='date' style={{ borderRadius: '0.4rem' }} />
                                        <i class="fa fa-calendar calender"></i>

                                        <button className='btn btn-primary mx-2' onClick={() => handleDatesDispatching()}>{t('btn2')}</button>
                                    </div>
                                    <form className="search mx-4" action="">
                                        <input value={searchString} onChange={(e) => setSearchString(e.target.value)} type="text" className="search-input" placeholder="Search product" id='searchText' name={searchString} />
                                        <i className="fa fa-search"></i>
                                    </form>
                                </div>

                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th scope="col" >#</th>
                                            <th scope="col" >{t('tableheading1')}</th>
                                            <th scope="col" >{t('tableheading2')}</th>
                                            <th scope="col" >{t('tableheading3')}</th>
                                            <th scope="col" >{t('tableheading4')}</th>
                                            <th scope="col" >{t('tableheading5')}</th>
                                            <th scope="col" >{t('tableheading6')}</th>
                                            <th scope="col" >{t('tableheading7')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productList?.map((elem, index) => {
                                                return (
                                                    <tr>
                                                        <td >{index + 1}</td>
                                                        <td >
                                                            <img
                                                                onClick={() => window.open(`/product_display_page_men/${elem?.url_link}`)}
                                                                className='img-fluid me-3' src={elem?.image} alt='' style={{ width: "60px", height: "60px", borderRadius: "6px", cursor: "pointer" }} />
                                                        </td>
                                                        <td
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => window.open(`/product_display_page_men/${elem?.url_link}`)}
                                                        >{elem?.name}</td>
                                                        <td >{elem?.quantity}</td>
                                                        <td >S${elem?.price}</td>
                                                        <td >{elem?.dateCreated}</td>
                                                        <td >
                                                            <span className={`badge ${elem?.status === "REJECTED" ? "badgeReject" : elem?.status === "APPROVED" ? "badgeApproved" : "badgePending"} rounded-pill text-dark`}>{elem?.status}</span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <div className="text-center d-flex justify-content-around">
                                                                <ProductModel productId={index} />
                                                                <ProductEditModel productId={index} />
                                                                <i onClick={() => showDeleteModel(elem?.name, elem?.id)} className="fa-regular fa-trash-can fa-lg p-2"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-center">
                                    <Pagination>
                                        <Pagination.Prev
                                            disabled={currentPage === 1}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        />

                                        {[...Array(Math.ceil(totalItems / itemsPerPage)).keys()].map((number) => (
                                            <Pagination.Item
                                                key={number + 1}
                                                active={number + 1 === currentPage}
                                                onClick={() => handlePageChange(number + 1)}
                                            >
                                                {number + 1}
                                            </Pagination.Item>
                                        ))}

                                        <Pagination.Next
                                            disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        />
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </>
    )
}

export default SellerProductsList