import React,{useState} from "react";
import { Link } from "react-router-dom"
import Loader from "../../../../../components/Loaders/Loader/loader";
import { AddToCart } from "../../../../../utils/cart/cartUtils";
import { AddToFavorite } from "../../../../../utils/favorite/favorite";
const Carts = (props) => {
    const maxLength = 80;
    const [expanded, setExpanded] = useState(false);
    const toggleDescription = () => {
        setExpanded(!expanded);
    };
    return (
        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img">
                    <Link to={`/${props?.gender === "MALE" ? "product_display_page_men" : "product_display_page_women"}/${props.url_link}`}>
                        <img src={props.image} className="img-fluid image-item" alt="" />
                    </Link>
                </div>
                <div className="member-info">
                    <h5 className='mb-0'>{props.name}</h5>
                    <h4>S${props.price}</h4>
                    <p>{expanded ? props?.description : props?.description?.length > maxLength ? `${props?.description?.slice(0, maxLength)}... ` : props?.description}
                        {props?.description?.length > maxLength && (
                            <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                {expanded ? 'Read less' : 'Read more'}
                            </button>
                        )}
                    </p>
                    <div className='d-flex align-items-center mb-2'>
                        <AddToCart id={props.id} />
                        <AddToFavorite id={props.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Carts;