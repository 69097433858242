import React from 'react'
import "./OurGuarantee.css"
import { useTranslation } from 'react-i18next';

const OurGuarantee = () => {
    const { t } = useTranslation('about');
    return (
        <section id="our-guarantee">
            <div className="container-fluid my-3" data-aos="fade-up">
                <div className="row px-5 mx-3">
                    <div className="section-title text-center">
                        <h2 className='text-uppercase'>{t('heading3')}</h2>
                    </div>
                    {[1, 2, 3, 4].map(elem => (
                        <div className="col-lg-6 mb-4">
                            <div className="card-box">
                                <div className="box-title d-flex align-items-center">
                                    <i className="fas fa-award"></i>
                                    <span>{t(`heading${elem + 4}`)}</span>
                                </div>
                                <p>{t(`p${elem+2}`)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default OurGuarantee