import "./product.css";
import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import optionsData from "../../../../../utils/constants/options";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from "universal-cookie";
// import Button from 'react-bootstrap/Button';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Pagination from "../Pagination";
import ReactPaginate from 'react-paginate';
import TrackMyOrder from "../../../Trackorders/subcomponents/TrackMyOrder";
import LoadingBar from 'react-top-loading-bar'
import Carts from "../Cart/Carts";
import Filters from "../Filters/filters";
import MyAlert from "../../../../../components/Alerts/Alert/alert";
import axios from "../../../../../services/api/axios";
import CustomSearchComponent from "../../../../../components/CustomSearch/CustomSearchComponent";
import { GlobalContext } from "../../../../../store/GlobalContext";
import { Alert } from "react-bootstrap";
import image from '../../../../../assets/images/webp/Frame-31.webp'
import { useTranslation } from "react-i18next";
const cookies = new Cookies();
export default function Productsitems(props) {
  const { state } = useContext(GlobalContext);
  const { t } = useTranslation('product')
  const [showPage, setShowPage] = useState(true)
  const [progress, setProgress] = useState(100)
  const [loader, setLoader] = useState()
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const axiosPrivate = useAxiosPrivate();
  const [favourites, setFavourites] = useState([]);
  const data = state.products ?? [];
  const [filteredList, setFilteredList] = useState([]);
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const countryOPAPI = state.countryList;
  const categoryOPAPI = state.categoryList;
  const ageOptions = (optionsData.age);
  const sizeOptions = (optionsData.size);
  const raceOptions = (optionsData.race);
  const [cartBool, setCartBool] = useState(false)
  const cartItem = state.cartItems;
  const [isFavourite, setIsFavourite] = useState(false);
  const [text, setText] = useState();
  const navigate = useNavigate();
  const [optionButton, setOptionButton] = useState(props.searchType)
  //Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredList.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredList.length;
    setItemOffset(newOffset);
  };
  const countryOptions = countryOPAPI.map((obj, index) => {
    return {
      label: obj.country_name,
      value: obj.country_name
    }
  });
  const categoryOptions = categoryOPAPI.map((obj, index) => {
    return {
      label: obj.name,
      value: obj.name
    }
  });
  const mystyle = {
    verticalAlign: "middle",
    fontSize: "1.5rem",
    border: "2px solid #fff",
    padding: "6px",
    borderRadius: "50%",
    color: "#E86669",

  };
  const mystyle1 = {
    verticalAlign: "middle",
    fontSize: "1.5rem",
    border: "2px solid #fff",
    padding: "6px",
    borderRadius: "50%",
    color: "#fff",

  };
  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     setLoading(true)
  //     await axios.get("/product_list/",
  //     )
  //       .then(res => {
  //         setData(res.data)
  //         props.setFilteredData(res.data)
  //         setProgress(100)
  //         setShowPage(true)
  //         setFilteredList(res.data)
  //         setLoading(false)
  //       })
  //   }
  //   fetchProducts();
  // }, [cartBool], [currentPage]);
  // useEffect(() => {
  //   axiosPrivate.get("product/favourites_list/",
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${cookies.get('access')}`
  //       },
  //       withCredentials: true
  //     }
  //   ).then(res => {
  //     setFavourites(res.data)
  //   })
  // }, [isFavourite])

  // <----filters function ended ----->
  const percentage = (value) => {
    return 20 * value / 100;
  }
  const searchToggle = () => {
    setOptionButton(!optionButton)
    props.setSearchType(!optionButton)
  }

  useEffect(() => {
    // <-----filters function----->


    const filterByAge = (filteredData) => {
      // Avoid filter for empty string
      if (!selectedAge) {
        return filteredData;
      }

      const filteredClothes = filteredData?.filter(
        (clothes) => {
          if (clothes.age == selectedAge) {
            return selectedAge;
          }
          //  clothes.age === selectedAge
        });
      return filteredClothes;
    };

    const filterByCountry = (filteredData) => {
      // Avoid filter for empty string
      if (!selectedCountry) {
        return filteredData;
      }

      const filteredClothes = filteredData?.filter(
        (clothes) => {
          if (clothes.country == selectedCountry) {
            return selectedCountry;
          }
          //  clothes.age === selectedAge
        });
      return filteredClothes;
    };

    const filterByCategory = (filteredData) => {
      // Avoid filter for empty string
      if (!selectedCategory) {
        return filteredData;
      }
      const filteredClothes = filteredData?.filter(
        (clothes) => {
          if (clothes.category == selectedCategory) {
            return selectedCategory;
          }
          //  clothes.age === selectedAge
        });
      return filteredClothes;
    };
    const filterBySize = (filteredData) => {
      // Avoid filter for empty string
      if (!selectedSize) {
        return filteredData;
      }

      const filteredClothes = filteredData?.filter(
        (clothes) => {
          if (clothes.bodyType == selectedSize) {
            return selectedSize;
          }
          //  clothes.age === selectedAge
        });
      return filteredClothes;
    };

    const filterByRace = (filteredData) => {
      // Avoid filter for empty string
      if (!selectedRace) {
        return filteredData;
      }

      const filteredClothes = filteredData?.filter(
        (clothes) => {
          if (clothes.race == selectedRace) {
            return selectedRace;
          }
          //  clothes.age === selectedAge
        });
      return filteredClothes;
    };
    var filteredData = filterByAge(data);
    filteredData = filterBySize(filteredData);
    filteredData = filterByCountry(filteredData);
    filteredData = filterByCategory(filteredData);
    filteredData = filterByRace(filteredData);
    filteredData = filteredData?.filter(p => p.gender === props.gender)
    setFilteredList(filteredData);
  }, [selectedAge, selectedSize, selectedCountry, selectedCategory, selectedRace, data, props]);

  const HandleAddToFavourite = async (id) => {
    setLoader(id)
    try {
      const response = await axiosPrivate.post(`product/add_to_favourite/${id}/`, {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('access')}`,
            'Accept-Language': state.language

          },
          withCredentials: true
        }
      );
      setIsFavourite(prev => !prev)
      setLoader("")
      //clear state and controlled inputs
      //need value attrib on inputs for this
    } catch (err) {
      setText(err.code)
      setLoader("")
    }
  }
  const HandleRemoveFromFavourite = async (id) => {
    setLoader(id)
    let favId;
    for (let i = 0; i < favourites.length; i++) {
      if (id === favourites[i].products) {
        favId = favourites[i].id;
      }
    }
    try {
      const response = await axiosPrivate.delete(`product/remove_from_favourite/${favId}/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('access')}`,
            'Accept-Language': state.language

          },
          withCredentials: true
        }
      );
      setIsFavourite(prev => !prev)
      setLoader("")
    } catch (err) {
      setText(err.code)
      setLoader("")
    }
  }
  const isInFav = (id) => {
    for (let i = 0; i < favourites.length; i++) {
      if (id === favourites[i].products) {
        return true;
      }
    }
    return false;
  }
  const AddToCart = async (id) => {
    setLoader(id)
    try {
      const response = await axiosPrivate.post(`/product/add_to_cart/${id}/`, {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('access')}`,
            'Accept-Language': state.language

          },
          withCredentials: true
        }
      );
      props.onChange(!props.checkCart)
      setCartBool(prev => !prev)
      setLoader("")
    } catch (err) {
      setText(err.code)
      setLoader("")
    }
  }
  const fun = (id) => {
    for (let i = 0; i < cartItem.length; i++) {
      if (id === cartItem[i].item.id) {
        return true;
      }
    }
    return false;
  }

  const filterData = currentItems?.
    map((g, index) => {
      if (g.gender === props.gender)
        return (
          <Carts
            key={index}
            {...g}
            percentage={percentage}
            index={index}
          />
        )
    })
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && props.query !== '') {
      navigate(`/searchpage/${props.query}`)
      // Call your function here
      // ...
    }
  };
  // const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
      <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />

      {showPage === true && <>
        <div className="d-lg-none d-md-none d-block mobile-search">
          {/* <div onKeyDown={handleKeyDown} style={{ marginTop: "2rem" }}>
            <CustomSearchComponent
              searchQuery={props.query}
              setSearchQuery={props.setQuery}
              searchType={optionButton}
            />
          </div> */}
          <div className="d-flex justify-content-center " style={{ marginTop: "1rem" }}>
            <button className="btn border-danger text-danger col-lg-5 col-md-6 col-sm-7 col-8  "
              onClick={searchToggle}>
              {optionButton ? "Search User Instead" : "Search Product Instead"}
            </button>
          </div>
        </div>
        {/* <div className="mobile-view-track">
          <TrackMyOrder />
        </div> */}
        <>
          <Filters
            ageOptions={ageOptions}
            sizeOptions={sizeOptions}
            countryOptions={countryOptions}
            categoryOptions={categoryOptions}
            raceOptions={raceOptions}
            location={props.location}
            btn={props.btn}
            genderbtn={props.genderbtn}
            ageOnChange={value => setSelectedAge(value)}
            sizeOnChange={value => setSelectedSize(value)}
            countryOnChange={value => setSelectedCountry(value)}
            categoryOnChange={value => setSelectedCategory(value)}
            raceOnChange={value => setSelectedRace(value)}
          />
          <MyAlert text={text} setText={setText} />
          {
            filterData?.length > 0 ?
              <div className="row feature-prod">
                {filterData}
              </div>
              : <>
                <div className='no-data'>
                  <img src={image} alt="logo" className='data-display' />
                </div>
              </>
          }
          {(currentItems?.length > 0 && pageCount > 1) && <div className="row"><div className="col-4"></div><div className="col-4">
            <ReactPaginate
              activeClassName="active"
              nextLinkClassName="page-link"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousLabel="previous"
              nextLabel="next"
              className=""
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              forcePage={pageOffset}
            /></div><div className="col-4"></div></div>}
        </>
      </>
      }
      {/* <Pagination
        postsPerPage={productPerPage}
        totalPosts={data.length}
        paginate={paginate}
      /> */}
    </>
  )
}
