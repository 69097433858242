import React from 'react'
import { useTranslation } from 'react-i18next';

const HowItWorksForBuyers = () => {
    const { t } = useTranslation('landingpage');
    return (
        <section id="how-it-works-buyer">
            <div className="container-fluid" data-aos="fade-up">
                <div className="text-center my-3">
                    <h2>{t('heading4')}</h2>
                </div>

                <div className="row px-5">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center mx-4">
                            <h1 className="display-1 num me-5">{t('no1')}</h1>
                            <p className="lh-base">{t('p2')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center mx-4">
                            <h1 className="display-1 num me-5">{t('no2')}</h1>
                            <p className="lh-base">{t('p2')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center mx-4">
                            <h1 className="display-1 num me-5">{t('no3')}</h1>
                            <p className="lh-base">{t('p2')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorksForBuyers;