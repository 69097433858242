import React, { useState, useContext, useEffect } from 'react';
import './Footer.css'
import logo from '../../assets/images/png/Fantasy_Senses_logo.png'
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { GlobalContext } from '../../store/GlobalContext';
function Footer() {
    const { t, i18n } = useTranslation('footer');
    const { state, dispatch } = useContext(GlobalContext);
    const [language, setLanguage] = useState(state.language);
    useEffect(() => {
        dispatch({
            type: "LANGUAGE",
            payload: language
        });
        i18n.changeLanguage(language);
    }, [language])
    return (
        <div className="footer">
            <div className='row px-5 mx-3 mb-4'>
                <div className="logo col-4 ps-0 pe-5">
                    <img src={logo} alt="Fantasy Senses" />

                    <div className="footer-brand-description">
                        {t('description')}
                    </div>

                    <div className="buttons">
                        <Link to='/product_page_men'> <button className="footer-buttons">{t('btn1')}</button></Link>
                        <Link to='/dash'> <button className="footer-buttons">{t('btn2')}</button></Link>
                    </div>
                </div>
                <div className="infor col-3 offset-2">
                    <h3>{t('heading1')}</h3>
                    <ul className="footer-ul">
                        <li><Link to="/">{t('link1')}</Link></li>
                        <li><Link to="">{t('link2')}</Link></li>
                        <li><Link to="/Contact">{t('link3')}</Link></li>
                        <li><Link to="/TermsAndConditions">{t('link4')}</Link></li>
                        <li><Link to="">{t('link5')}</Link></li>
                        <li><Link to="/About">{t('link6')}</Link></li>
                    </ul>
                </div>
                <div className="language col-3">
                    <h3>{t('dropdowntitle')}</h3>
                    <Dropdown style={{ marginTop: "1.6rem", borderRadius: "20px" }}>
                        <Dropdown.Toggle className="footer-dropdown" id="dropdown-basic" style={{ backgroundColor: "black", borderColor: "black", borderRadius: "20px" }}>
                            {language === "en" ? t('dropdown1') : t('dropdown2')}

                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ borderRadius: "20px" }}>
                            <button className="dropdown-item" type="button" onClick={() => setLanguage("en")} >{t('dropdown1')}</button>
                            <button className="dropdown-item" type="button" onClick={() => setLanguage("zh")} >{t('dropdown2')}</button>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default Footer;
