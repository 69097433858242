import React, { useContext, useEffect, useState } from "react";
import "./OrdersList.css"
import MyAlert from "../../../components/Alerts/Alert/alert";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import LoadingBar from 'react-top-loading-bar'
import Header from "../../../layout/Header/Header";
import SearchAndGenderSection from "../../../components/SearchAndGenderSection/SearchAndGenderSection";
import Footer from "../../../layout/Footer/Footer";
import axios from "../../../services/api/axios";
import ConfirmationModel from "../../../components/Modal/ConfirmationModel/ConfirmationModel";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../store/GlobalContext";
const cookies = new Cookies();

const OrdersList = () => {
    const { t } = useTranslation('sellerorderlist')
    const { state } = useContext(GlobalContext);
    const [showPage, setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const [dashboarData, setDashboardData] = useState({})
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [text, setText] = useState('');
    const [searchString, setSearchString] = useState("");
    const [status, setStatus] = useState("")
    const [showModel, setShowModel] = useState(false)
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showAlert, setShowAlert] = useState('')
    const [refresh, setRefresh] = useState(false);
    const [apiParams, setApiParams] = useState({
        startDate: "",
        endDate: ""
    })
    useEffect(() => {
        axios.get(`seller/dashboard/?search_value=${searchString}&start_date=${apiParams.startDate}&end_date=${apiParams.endDate}&limit=&offset`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${cookies.get('access')}`,
                'Accept-Language': state.language,

            },
            withCredentials: true
        }).then(res => {
            setDashboardData(res?.data)
            setShowPage(true);
            setProgress(100);
        }).catch(err => {
            console.log("err", err)
        })
    }, [apiParams, searchString, refresh])

    useEffect(() => {
        if (!showModel) {
            setStatus("")
        }
    }, [showModel])
    useEffect(() => {
        if (status !== "") {
            if (selectedItems?.length !== 0) {
                setShowModel(true)
            } else {
                alert("Make sure you have selected an order first!")
                setStatus("");
            }
        }
    }, [status])
    function handleDatesDispatching() {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateRegex.test(startDate) && dateRegex.test(endDate)) {
            const ordersApiParams = { ...apiParams }
            ordersApiParams.startDate = startDate;
            ordersApiParams.endDate = endDate;
            setApiParams(ordersApiParams)
        } else {
            alert("please provide valid dates")
        }
    }
    const changeStatus = () => {
        axios.put("seller/orders/change-status/", {
            orders: selectedItems,
            status: status
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${cookies.get('access')}`,
                'Accept-Language': state.language

            },
            withCredentials: true
        }).then(res => {
            console.log(res, 'res')
            setSelectedItems([])
            setSelectAll(false)
            setShowAlert("Status has been changed for selected orders");
            setRefresh(!refresh)
            setShowModel(false);
        }).catch(err => {
            console.log(err, 'err')
        })
    }
    const handleMasterCheckboxChange = () => {
        setSelectAll(!selectAll);
        const allItemIds = dashboarData?.recent_orders?.orders?.map((elem) => elem.id);
        setSelectedItems(selectAll ? [] : allItemIds);
    };

    const handleCheckboxChange = (itemId) => {
        const updatedSelectedItems = selectedItems.includes(itemId)
            ? selectedItems.filter((id) => id !== itemId)
            : [...selectedItems, itemId];
        setSelectedItems(updatedSelectedItems);
        setSelectAll(updatedSelectedItems.length === dashboarData?.recent_orders?.orders?.length);
    };
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            <ConfirmationModel showModel={showModel} setShowModel={setShowModel} status={status} changeStatus={changeStatus} />
            {showPage === true && <>
                <Header />
                <SearchAndGenderSection />
                <MyAlert text={text} setText={setText} />
                <section id='ordersList'>
                    <div className='row px-5 mx-3'>
                        <div className='card mb-5'>
                            <div className={showAlert ? "alert alert-success alert-dismissible fade show m-3" : "offscreen"}>
                                {showAlert}
                                <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                            </div>
                            <div className='p-4'>
                                <h2 className='text-uppercase m-0'>{t('heading1')}</h2>
                            </div>

                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div className="dropdown mx-4">
                                    <button className="btn-status dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {t('dropdown')}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link className="dropdown-item" to="" onClick={() => setStatus("READY_TO_DISPATCH")}>{t('status2')}</Link></li>
                                        <li><Link className="dropdown-item" to="" onClick={() => setStatus("DISPATCHED")}>{t('status3')}</Link></li>
                                    </ul>
                                </div>
                                <div>
                                    <span className='mx-2'>{t('text1')}:</span>
                                    <input value={startDate} onChange={(e) => setStartDate(e.target.value)} type='date' style={{ borderRadius: '0.4rem' }} />
                                    <i class="fa fa-calendar calender"></i>
                                    <span className='mx-2 mt-2'>to</span>
                                    <input value={endDate} onChange={(e) => setEndDate(e.target.value)} type='date' style={{ borderRadius: '0.4rem' }} />
                                    <i class="fa fa-calendar calender"></i>
                                    <button className='btn btn-primary mx-2' onClick={() => handleDatesDispatching()}>GO</button>
                                </div>
                                <form className="search mx-4" action="">
                                    <input value={searchString} onChange={(e) => setSearchString(e.target.value)} type="text" className="search-input" placeholder="Search tracking #" id='searchText' name={searchString} />
                                    <i className="fa fa-search"></i>
                                </form>
                            </div>

                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" className='text-center'>
                                            <div className="form-check my-auto">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="customCheck1"
                                                    checked={selectAll}
                                                    onChange={handleMasterCheckboxChange}
                                                />
                                            </div>
                                        </th>
                                        <th scope="col" className='text-center'>{t('tableheading1')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading2')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading3')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading4')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading5')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading8')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading6')}</th>
                                        <th scope="col" className='text-center'>{t('tableheading7')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboarData?.recent_orders?.orders?.map((elem, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td className='text-center'>
                                                        <div className="form-check my-auto">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`customCheck-${elem.id}`}
                                                                checked={selectedItems.includes(elem.id)}
                                                                onChange={() => handleCheckboxChange(elem.id)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>{elem?.id}</td>
                                                    <td className='text-center'>
                                                        <img className='img-fluid' src={elem?.product_image} alt='' style={{ width: "60px", height: "60px", borderRadius: "6px" }} />
                                                    </td>
                                                    <td className='text-center'>{elem?.seller_tracking_no}</td>
                                                    <td className='text-center'>{elem?.product_name}</td>
                                                    <td className='text-center'>S${elem?.orderTotal}</td>
                                                    <td className='text-center'>{elem?.buyer_username}</td>
                                                    <td className='text-center'>{elem?.created_at}</td>
                                                    <td className='text-center'>
                                                        <span className="badge badgeInit rounded-pill text-dark">{elem?.orderStatus}</span>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
            }
        </>
    )
}

export default OrdersList